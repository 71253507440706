*{
    margin: 0px;
    padding: 0;
    box-sizing: border-box;
    
}

body{
    margin: 0px ;
    height: 100%;
    width: 100%;
    background-color: black;
}

html{
    height: 100%;
    width: 100%;
}

#root{

}


::-webkit-scrollbar {
    width: 4px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
   background-color:transparent
  }
   
  /* Handle */
   ::-webkit-scrollbar-thumb {
    background: grey; 
    border-radius: 10px;
  }
  
  /* Handle on hover */
   ::-webkit-scrollbar-thumb:hover {
    background: #b30000; 
  }   

/* Styles for landscape smartphones and small tablets */
@media (min-width: 480px) {
    /* Your styles for landscape smartphones and small tablets */
}

/* Styles for tablets and small laptops */
@media (min-width: 768px) {
    /* Your styles for tablets and small laptops */
}

/* Styles for medium to large laptops */
@media (min-width: 1024px) {
    /* Your styles for medium to large laptops */
}

/* Styles for desktop screens */
@media (min-width: 1200px) {
    /* Your styles for desktop screens */
}

/* Styles for larger desktop screens */
@media (min-width: 1440px) {
    /* Your styles for larger desktop screens */
}